import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex } from '@components/base/Flex'
import { Text } from '@components/base/Text'
import LogoImage from '../../img/logo.svg'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useScrollPosition } from '../../hooks/use-scroll-position'
import IconButton from '@components/base/IconButton'
import { breakpoints } from '../../styles/utils'
import { useOutsideClick } from '../../hooks/use-outside-click'
import { useLocation } from '@reach/router'

const Logo = styled(LogoImage)`
    height: 50px;
    width: auto;
    color: ${({ theme }) => theme.color.accent};
`

const NavContainer = styled(Flex)<{ isTop: boolean }>`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    transition: background-color 250ms linear, opacity 250ms linear;
    background-color: ${({ theme, isTop }) => (isTop ? 'transparent' : theme.color.background)};
    padding: 10px 0;
    opacity: 0.9;

    &:hover {
        opacity: 1;
    }
`
const MenuItems = styled(Flex)`
    align-self: center;
    flex-direction: column;
    top: 0;
    right: 0;

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: row;
    }
`

const MenuOption = styled(Link)`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;
    text-decoration: none;
    color: ${({ theme }) => theme.color.accent};

    &:after {
        transition: width 0.2s;
        position: absolute;
        content: '';
        height: 2px;
        width: 0;
        bottom: -5px;
        background: white;
    }

    &:hover,
    &:focus,
    &[data-active='true'] {
        :after {
            transition: width 0.2s;
            width: 100%;
        }
    }
`

const BoxedContainer = styled(Flex)`
    height: 50px;
    max-height: 50px;
    max-width: 90%;
    width: ${breakpoints.xl}px;
    align-self: center;
    justify-content: space-between;
`

const PlaceholderNav = styled(Flex)<{ occupySpace: boolean }>`
    height: ${({ occupySpace }) => (occupySpace ? 70 : 0)}px;
`
const StyledIcon = styled(IconButton)`
    display: inline-block;
    align-content: flex-end;

    @media (min-width: ${breakpoints.md}px) {
        display: none;
    }
`

const MenuContainer = styled(Flex)<{ isOpen: boolean }>`
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.background};
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    transition: transform 0.5s ease-in-out;
    transform: translateX(${({ isOpen }) => (isOpen ? 0 : '100%')});
    height: 100vh;
    row-gap: 20px;
    column-gap: 30px;

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: row;
        background-color: transparent;
        position: relative;
        transform: translateX(0);
        width: auto;
        height: auto;
    }
`

const languageMapping: Record<string, string> = {
    en: 'AR',
    ar: 'EN',
}
export default function Navbar({ occupySpace }: { occupySpace: boolean }) {
    const { language, originalPath, t } = useI18next()
    const route = useLocation()
    const [isTop, setIsTop] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef<HTMLElement | undefined>(undefined)
    useOutsideClick(ref, () => setIsOpen(false))
    useScrollPosition(
        ({ currPos }) => {
            setIsTop(currPos.y === 0)
        },
        [],
        null,
        false
    )
    const MenuOptions = (
        <MenuContainer ref={ref} isOpen={isOpen}>
            <StyledIcon onClick={() => setIsOpen(false)} size={'small'} name={'mdiClose'} color={'accent'} />

            <MenuOption data-active={route.pathname.endsWith('/')} to={'/'}>
                <Text uppercase>{t('menu:home')}</Text>
            </MenuOption>
            <MenuOption data-active={route.pathname.endsWith('/grand-cafe')} to={'/grand-cafe'}>
                <Text uppercase>{t('menu:grand-cafe')}</Text>
            </MenuOption>
            <MenuOption data-active={route.pathname.endsWith('/booking')} to={'/booking'}>
                <Text uppercase>{t('menu:booking')}</Text>
            </MenuOption>
            <MenuOption data-active={route.pathname.endsWith('/contact')} to={'/contact'}>
                <Text uppercase>{t('menu:contact')}</Text>
            </MenuOption>
            <MenuOption to={originalPath} language={language === 'en' ? 'ar' : 'en'}>
                <Text uppercase>{languageMapping?.[language] || 'Language'}</Text>
            </MenuOption>
        </MenuContainer>
    )

    return (
        <PlaceholderNav occupySpace={occupySpace}>
            <NavContainer isTop={isTop}>
                <BoxedContainer direction={'row'} alignItems={'center'}>
                    <Link to={'/'}>
                        <Logo alt="Logo" />
                    </Link>
                    <MenuItems direction={'row'} justifyContent={'flex-end'}>
                        <StyledIcon onClick={() => setIsOpen(true)} size={'small'} name={'mdiMenu'} color={'accent'} />
                        {MenuOptions}
                    </MenuItems>
                </BoxedContainer>
            </NavContainer>
        </PlaceholderNav>
    )
}
