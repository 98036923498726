import styled from 'styled-components'
import React from 'react'
import { DefaultColors } from '@components/base/Icon'

export const BaseFlex = styled.div<{
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    alignItems?: CSSStyleDeclaration['alignItems']
    justifyContent?: CSSStyleDeclaration['justifyContent']
    padding?: Spacing
    onClick?: () => void
    margin?: Spacing
    background?: Colors | DefaultColors
    flex?: number
}>`
    flex-direction: ${({ direction = 'column' }) => direction};
    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ padding, theme }) => padding && `padding: ${theme.size.spacing[padding as Spacing]}px;`}
  ${({ margin, theme }) => margin && `margin: ${theme.size.spacing[margin]}px;`}
  ${({ background, theme }) => background && `background-color: ${theme.color[background as Colors] || background};`};

    &:hover {
        cursor: ${({ onClick }) => (onClick ? `pointer` : 'auto')};
    }
    flex-shrink: 0;
    display: flex;
`

export const Flex = React.forwardRef<
    HTMLDivElement,
    {
        onClick?: () => void
        children?: React.ReactNode
        direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
        alignItems?: CSSStyleDeclaration['alignItems']
        justifyContent?: CSSStyleDeclaration['justifyContent']
        padding?: Spacing
        margin?: Spacing
        background?: Colors | DefaultColors
        flex?: number
    } & Partial<Omit<HTMLDivElement, 'children'>>
>(
    (
        {
            children,
            onClick,
            direction = 'column',
            margin,
            background,
            flex,
            alignItems,
            padding,
            justifyContent,
            ...rest
        },
        ref
    ) => (
        <BaseFlex
            {...{
                ref,
                direction,
                flex,
                margin,
                onClick,
                alignItems,
                padding,
                justifyContent,
                background,
                ...rest,
            }}
        >
            {children}
        </BaseFlex>
    )
)

export const PrimaryFlex: typeof Flex = styled(Flex)`
    background-color: ${({ theme }) => theme.color.primary};
`

export const AccentFlex: typeof Flex = styled(Flex)`
    background-color: ${({ theme }) => theme.color.accent};
`
