import * as React from 'react'
import { Text } from '@components/base/Text'
import styled from 'styled-components'
import { DefaultColors } from '@components/base/Icon'
import { Link } from 'gatsby-plugin-react-i18next'

type ButtonProps = {
    onClick?: () => void
    Icon?: any
    disabled?: boolean
    testID?: string
    textColor?: Colors | DefaultColors
    children?: React.ReactNode
    fullWidth?: boolean
    centered?: boolean
    href?: string
    width?: number
}

const BaseButtonComponent = styled(Link)<{ width?: number }>`
    padding: 8px;
    margin: 5px;
    border-style: solid;
    flex-direction: row;
    border-width: 1px;
    border-color: ${({ theme }) => theme.color.accent};
    color: ${({ theme }) => theme.color.accent};
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    ${({ width }) => (width ? `width: ${width}px` : ``)}
`

export const BaseButton = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, 'titleStyle' | 'buttonStyle'>>(
    (
        { Icon, children, href, centered = true, onClick, width, textColor = 'white', testID, ...props }: ButtonProps,
        ref
    ) => {
        return (
            <BaseButtonComponent
                as={!href ? 'button' : undefined}
                type={'button'}
                to={href || ''}
                width={width}
                onClick={onClick}
                {...props}
                ref={ref}
            >
                {Icon}
                {children && (
                    <Text type={'body'} fullWidth={props.fullWidth} centered={centered}>
                        {children}
                    </Text>
                )}
            </BaseButtonComponent>
        )
    }
)

export const AccentButton = styled((props) => (
    <BaseButton {...props} textColor={props.disabled ? 'accent' : 'white'} />
))`
    border-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.accent};
    :disabled {
        opacity: 0.2;
    }
`

export const PrimaryButton: typeof BaseButton = styled((props) => <BaseButton {...props} textColor="accent" />)`
    border-color: ${({ theme }) => theme.color.accent};
    color: ${({ theme }) => theme.color.accent};
    background-color: transparent;
`
