import { RefObject, useEffect } from 'react'

export function useOutsideClick(ref: RefObject<HTMLElement>, handler: () => void) {
    useEffect(() => {
        function handleClickOutside(event: HTMLElementEventMap['mousedown']) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler()
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}
