import React, { RefObject } from 'react'
import styled from 'styled-components'
import Icon, { IconProps } from '@components/base/Icon'
import { BaseButton } from '@components/base/Button'
import { Flex } from '@components/base/Flex'

type IconButtonProps = {
    buttonRef?: RefObject<HTMLButtonElement>
    background?: Colors
    disabled?: boolean
    onClick: () => void
    label?: string
    testID?: string
    color?: string
    size?: IconSizes
    loading?: boolean
    textColor?: Colors
    style?: ElementCSSInlineStyle
    horizontal?: boolean
} & IconProps

type BaseIconButtonProps = {
    backgroundColor?: Colors
    horizontal?: boolean
}

const BaseIconButton = styled(BaseButton)<BaseIconButtonProps>`
    flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
    align-items: center;
    border: none;
    padding: 0;
    background: ${({ theme, backgroundColor }) => (backgroundColor ? theme.color[backgroundColor] : 'transparent')};
`

export default React.forwardRef<HTMLButtonElement, IconButtonProps>(
    (
        {
            loading,
            disabled = false,
            name,
            size = 'medium',
            color,
            background,
            onClick,
            label,
            horizontal = true,
            ...iconBaseProps
        },
        ref
    ) => {
        return (
            <BaseIconButton
                horizontal={horizontal}
                backgroundColor={background}
                onClick={onClick}
                disabled={disabled}
                ref={ref}
                {...iconBaseProps}
            >
                <Icon name={loading ? 'mdiLoading' : name} color={disabled ? 'gray' : color ?? 'accent'} size={size} />
            </BaseIconButton>
        )
    }
)
