import React from 'react'
import styled from 'styled-components'
import { Flex } from '@components/base/Flex'
import { Text } from '@components/base/Text'
import { breakpoints } from '../../styles/utils'

const NavContainer = styled(Flex)`
    padding: 5px 5px;
    background-color: ${({ theme }) => theme.color.background};
`
const MenuItems = styled(Flex)`
    align-self: center;
    column-gap: 30px;

    @media (max-width: ${breakpoints.md}px) {
        justify-content: space-between;
    }
`

const BoxedContainer = styled(Flex)`
    height: 50px;
    max-width: ${breakpoints.xl}px;
    width: 100%;
    align-self: center;
    color: ${({ theme }) => theme.color.accent};

    @media (max-width: ${breakpoints.md}px) {
        justify-content: center;
    }
`

const Copyrights = styled(MenuItems)`
    @media (max-width: ${breakpoints.md}px) {
        display: none;
    }
`
export default function Navbar() {
    return (
        <NavContainer alignItems={'center'}>
            <BoxedContainer direction={'row'} alignItems={'center'}>
                <MenuItems flex={1} direction={'row'}>
                    <Text as={'a'} uppercase href={'tel:+97082849498'}>
                        +970 8 284 9498
                    </Text>

                    <Text as={'a'} uppercase href={'mailto:info@grandpalace.ps'}>
                        info@grandpalace.ps
                    </Text>
                </MenuItems>
                <Copyrights flex={1} direction={'row'} justifyContent={'flex-end'}>
                    <Text uppercase>© Grand Palace Hotel</Text>
                </Copyrights>
            </BoxedContainer>
        </NavContainer>
    )
}
