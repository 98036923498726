import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'
import '../../styles/base.css'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { ContentWrapper } from '@components/Layout/ContentWrapper'

const theme: DefaultTheme = {
    color: {
        primary: '#003157',
        accent: '#fff',
        background: '#111111',
    },
    size: {
        icons: {
            small: 1,
            medium: 2,
            large: 3,
            extra_large: 4,
            super_large: 5,
        },
        spacing: {
            small: 10,
            medium: 20,
            large: 30,
            extra_large: 50,
        },
        title: 54,
        h1: 48,
        h2: 28,
        h3: 16,
        body: 12,
        hint: 10,
    },
}

type LayoutProps = {
    children: React.ReactNode
    occupySpace?: boolean
    layoutType?: 'fullScreen' | 'boxed'
}

const TemplateWrapper = ({ children, occupySpace = true, layoutType = 'fullScreen' }: LayoutProps) => {
    const { title, description } = useSiteMetadata()
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />

                <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
                <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-32x32.png`} sizes="32x32" />
                <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-16x16.png`} sizes="16x16" />

                <link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#ff4400" />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
            </Helmet>
            <ThemeProvider theme={theme}>
                <Navbar occupySpace={occupySpace} />
                <ContentWrapper occupySpace={occupySpace} layoutType={layoutType}>
                    {children}
                </ContentWrapper>
                <Footer />
            </ThemeProvider>
        </>
    )
}

export default TemplateWrapper
