import { Flex } from '@components/base/Flex'
import React from 'react'
import styled from 'styled-components'

type ContentWrapperProps = {
    children: React.ReactNode
    occupySpace: boolean
    layoutType: 'fullScreen' | 'boxed'
}

const StyledFlex = styled(Flex)<{ occupySpace: boolean; layoutType: ContentWrapperProps['layoutType'] }>`
    min-height: ${({ occupySpace }) => (occupySpace ? `calc(100vh - 70px)` : `100vh`)};
    overflow-y: auto;
    max-width: ${({ layoutType }) => (layoutType === 'fullScreen' ? '100%' : 'var(--xl)')};
    margin: auto;
`

export const ContentWrapper = ({ children, occupySpace, layoutType }: ContentWrapperProps) => {
    return (
        <StyledFlex occupySpace={occupySpace} layoutType={layoutType}>
            {children}
        </StyledFlex>
    )
}
