import styled, { DefaultTheme } from 'styled-components'
import { DefaultColors } from '@components/base/Icon'
import React, { HTMLProps } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

export type TextType = 'h1' | 'h2' | 'h3' | 'body' | 'title' | 'hint'

export type SharedStyle = {
    type?: TextType
    color?: Colors | DefaultColors
    uppercase?: boolean
    lowercase?: boolean
    capitalize?: boolean
    underline?: boolean
    centered?: boolean
    fullWidth?: boolean
    bolded?: boolean
    background?: Colors | DefaultColors
    fontFamily?: 'Montserrat' | 'ZCool'
    textAlign?: 'right' | 'left' | 'auto'
}

export const commonTextStyledProps = (
    theme: DefaultTheme,
    {
        bolded,
        fontFamily = 'Montserrat',
        capitalize,
        centered,
        color,
        lowercase,
        type = 'body',
        underline,
        background = 'transparent',
        uppercase,
        textAlign,
    }: SharedStyle
) => `
    font-family: ${fontFamily}, serif;
	font-weight: ${bolded ? 'bold' : 'normal'};
	color: ${theme.color[color as Colors] || color || 'inherit'};
	font-size: ${theme.size[type] || theme.size.body}px;
	text-transform: ${uppercase ? 'uppercase' : lowercase ? 'lowercase' : capitalize ? 'capitalize' : 'none'};
	background-color: ${theme.color[background as Colors] || background};
	text-decoration-line: ${underline ? 'underline' : 'none'};
	text-align: ${centered ? 'center' : textAlign || 'auto'};
	flex-wrap: wrap;
    letter-spacing: 1px;
    display: block;
    margin: 5px;
`

const BaseText = styled.p<SharedStyle>`
    ${({ theme, ...rest }) => commonTextStyledProps(theme, rest)}
`

const typeMapping: Record<
    SharedStyle['type'],
    Extract<keyof HTMLElementTagNameMap, 'title' | 'h1' | 'h2' | 'body' | 'span'>
> = {
    body: 'span',
    h1: 'h1',
    h2: 'h2',
    hint: 'span',
    title: 'title',
}
export const Text = (props: SharedStyle & HTMLProps<HTMLElement>) => {
    const { language } = useI18next()
    return (
        <BaseText
            as={props.type ? typeMapping[props.type] : 'span'}
            textAlign={language === 'ar' ? 'right' : 'auto'}
            {...props}
        />
    )
}
